.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2rem;
  font-weight: 500;
  border: 0;
  border-radius: 2.5rem;
}

.active {
  color: var(--main-white);
  background-color: var(--main-black);
}

.active img {
  filter: invert(100%);
}

.not-active {
  color: var(--main-black);
  background-color: none;
}

.nav-item-title {
  margin-left: 1rem;
  font-size: 1.4rem;
}
