.account {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 1rem;
  border-left: 1px solid var(--main-violet);
}

.account-info {
  margin-right: 1rem;
  padding: 0 1rem;
}

.account-info-name {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.account-info-role {
  font-size: 1rem;
  font-weight: 500;
  color: var(--main-violet);
}

.account-menu {
  position: absolute;
  top: 100%;
  right: 0;
}
