.logo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "Scripticus";
  line-height: 100%;
}

.logo-title {
  color: var(--main-black);
}

.logo-subtitle {
  margin-top: .7rem;
  color: var(--main-violet);
}
