.type-item-menu-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.type-item-menu {
  position: absolute;
  top: 100%;
  right: 0;
}
