.border-wrap {
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 2px 2px 10px rgba(215, 215, 244, 0.5);
}

.border-wrap-white {
  background-color: var(--main-white);
}

.border-wrap-violet {
  background-color: var(--main-violet);
}

.border-wrap-yellow {
  background-color: var(--main-yellow);
}
