.no-page-404 {
  font-family: "Scripticus";
  font-size: 10rem;
  color: var(--main-orange);
}

.no-page-link {
  font-size: 2rem;
  text-decoration: underline;
}
