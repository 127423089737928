.modal-wrap {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  width: 100%;
  background-color: var(--bg-violet-dark);
}

.modal-window {
  height: 100vh;
  min-width: 20rem;
  padding: 3rem;
  background-color: var(--main-white);
  overflow: auto;
}

.modal-title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
