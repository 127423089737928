.text-button-black,
.text-button-white {
  position: relative;
  padding: 2rem 3rem;
  border-radius: 3rem;
}

.text-button-black {
  color: var(--main-white);
  background-color: var(--main-black);
}

.text-button-white {
  color: var(--main-black);
  border: 1px solid var(--main-black);
}

.text-button-preloader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.text-button:disabled,
.text-button-black:disabled {
  opacity: 0.7;
}
