.text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.3rem;
}

.text-input label {
  font-size: 1.2rem;
}

.text-input input {
  width: 100%;
  padding: 0.5rem 1rem;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid var(--main-violet);
  outline: none;
}

.text-input input:focus {
  border-bottom: 1px solid var(--main-black);
}
