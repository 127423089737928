.grid-wrap {
  display: grid;
  gap: 1rem;
}

.grid-wrap-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-wrap-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-wrap-4 {
  grid-template-columns: repeat(4, 1fr);
}
