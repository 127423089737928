.preloader-small {
  width: 1.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--main-violet) 94%, #0000)
      top/6px 6px no-repeat,
    conic-gradient(#0000 30%, var(--main-violet));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: l13 1s infinite linear;
}

.preloader-normal {
  width: 5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--main-violet) 94%, #0000)
      top/10px 10px no-repeat,
    conic-gradient(#0000 30%, var(--main-violet));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
